import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  Center,
  useDisclosure,
  Button,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Spinner,
  Image,
  Circle,
  Square,
  Wrap,
  WrapItem,
  Switch as CSwitch,
} from '@chakra-ui/react';

import { FaImage, FaEdit, FaUser } from 'react-icons/fa';

import { AiFillFileAdd } from 'react-icons/ai';

import { useLocation } from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Files from 'react-files';
import ReactPlayer from 'react-player';

import Logo from '../assets/svhq_logo.png';

const FeaturedPitchesX = ({ title, subtitle, path }) => {
  const store = useContext(MainStore);
  let location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);

  const [selected, setSelected] = useState();
  const [label, setlabel] = useState('');
  const [url, seturl] = useState('');
  const [order, setorder] = useState(null);
  const [userid, setuserid] = useState(null);
  const [cat, setcat] = useState(null);
  const [featured, setfeatured] = useState(false);
  const [image, setimage] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setloading] = useState(false);

  const [show, setShow] = useState(false);

  const onFilesChange = files => {
    store._globalToast({
      title: 'File loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    setimage(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onFilesError = (error, file) => {
    if (store.debugMode)
      console.log('error code ' + error.code + ': ' + error.message);
  };

  const clearFields = () => {
    setlabel('');
    seturl('');
    setorder('');
    setcat('');
    setuserid('');
    setfeatured(false);
    setimage('');
    setSelected(null);
    setimage('');
    setFile(null);
  };

  const updateData = () => {
    store._getInsights().then(res => {
      if (res) {
        if (store.userData.user.user_type === 'admin') {
          setData(res.data.sort((a, b) => a.priority - b.priority));
        } else {
          const featured = res.data
            .filter(d => d.feature)
            .sort((a, b) => a.priority - b.priority);
          setData(featured);
        }
      }
    });
  };

  const processData = tempData => {
    var result = tempData.reduce((x, y) => {
      (x[y.category] = x[y.category] || []).push(y);

      return x;
    }, {});

    var arr = [];

    Object.keys(result).forEach(function (key, index) {
      arr.push([key, result[key]]);
    });

    arr = arr.sort((a, b) => a[1].priority - b[1].priority);

    console.log(arr);

    return arr;
  };

  useEffect(() => {
    if (location.pathname === path) {
      updateData();
    }
    return () => {};
  }, [location]);

  return (
    <Box px="36px" py="12px">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected ? 'Edit' : 'Add New'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="10px">
              <Files
                className="files-dropzone"
                onChange={files => onFilesChange(files)}
                onError={onFilesError}
                accepts={['image/*']}
                multiple={false}
                // maxFileSize={10000000}
                // minFileSize={0}
                clickable
              >
                {image ? (
                  <Image
                    m="0 auto"
                    cursor="pointer"
                    boxSize="200px"
                    objectFit="cover"
                    src={image ? image : ''}
                    alt="Thumbnail"
                    ignoreFallback
                  />
                ) : (
                  <Square
                    m="0 auto"
                    boxSize="200px"
                    border="1px solid gray"
                    borderRadius={4}
                    backgroundColor="white"
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                  >
                    <Icon
                      as={FaImage}
                      w={'40px'}
                      h={'40px'}
                      // mb={5}
                    />
                  </Square>
                )}
              </Files>
              <Input
                value={label}
                onChange={e => setlabel(e.target.value)}
                type="text"
                placeholder="Title"
              />
              <Input
                value={url}
                onChange={e => seturl(e.target.value)}
                type="text"
                placeholder="URL"
              />
              <Input
                value={order}
                onChange={e => setorder(e.target.value)}
                type="number"
                placeholder="Order"
              />
              <Input
                value={cat}
                onChange={e => setcat(e.target.value)}
                type="text"
                placeholder="Category Name"
              />
              <Input
                display="none"
                value={userid}
                onChange={e => setuserid(e.target.value)}
                type="number"
                placeholder="User ID"
              />
              <HStack>
                <CSwitch
                  size="md"
                  isChecked={featured}
                  onChange={e => setfeatured(e.target.checked)}
                />
                <Text>Featured</Text>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            {selected ? (
              <>
                <Button
                  isLoading={loading}
                  colorScheme="red"
                  mr={3}
                  onClick={() => {
                    setloading(true);
                    store
                      ._updateInsight({
                        id: selected.id,
                        delete: true,
                      })
                      .then(res => {
                        setloading(false);
                        if (res) {
                          updateData();
                          clearFields();
                          onClose();
                        }
                      });
                  }}
                >
                  Delete
                </Button>
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  onClick={() => {
                    setloading(true);
                    store
                      ._updateInsight({
                        title: label,
                        url,
                        priority: order,
                        feature: featured,
                        thumbnail: file,
                        id: selected.id,
                        edit: true,
                        user_id: userid,
                        category_name: cat,
                      })
                      .then(res => {
                        setloading(false);
                        if (res) {
                          updateData();
                          clearFields();
                          onClose();
                        }
                      });
                  }}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                isLoading={loading}
                loadingText="Saving..."
                colorScheme="blue"
                onClick={() => {
                  setloading(true);
                  store
                    ._updateInsight({
                      title: label,
                      url,
                      priority: order,
                      feature: featured,
                      thumbnail: file,
                      category_name: cat,
                    })
                    .then(res => {
                      setloading(false);
                      if (res) {
                        updateData();
                        clearFields();
                        onClose();
                      }
                    });
                }}
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VideoPlayer
        isOpen={show}
        data={selected}
        onClose={() => setShow(false)}
      />

      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'initial', lg: 'center' }}
        justifyContent="flex-start"
      >
        <HStack>
          {store.userData && store.userData.user.user_type === 'admin' && (
            <IconButton
              icon={<AiFillFileAdd />}
              colorScheme="blue"
              onClick={() => {
                onOpen();
                setlabel('');
                seturl('');
                setorder('');
                setfeatured(false);
                setimage('');
                setSelected(null);
              }}
            />
          )}
          <Text fontSize="24px" fontWeight="bold">
            {title}
          </Text>
        </HStack>
        <Text ml={{ base: '0px', lg: '10px' }} fontSize="16px" color="grey">
          {subtitle}
        </Text>
      </Flex>
      {processData(data)
        .filter(f => f[0].includes('Season'))
        .map((c, i) => {
          return (
            <Box>
              <Text fontSize="20px" fontWeight="bold">
                {c[0]}
              </Text>
              <Wrap mt="20px" spacing="40px">
                {c[1].map(d => {
                  return (
                    <WrapItem key={`wi${i}`}>
                      <Box
                        w="240px"
                        h="240px"
                        onClick={() => {
                          if (store.debugMode) console.log(d);
                          if (d.category.includes('video')) {
                            setShow(true);
                          } else {
                            window.open(d.url);
                          }
                        }}
                        cursor="pointer"
                        pos="relative"
                      >
                        {d.thumbnail ? (
                          <Box
                            h="60%"
                            backgroundColor="black"
                            backgroundImage={d.thumbnail}
                            backgroundSize="cover"
                            backgroundRepeat="no-repeat"
                            backgroundPosition="center center"
                            opacity={d.feature ? 1 : 0.5}
                          ></Box>
                        ) : (
                          <Box
                            h="60%"
                            backgroundColor="black"
                            opacity={d.feature ? 1 : 0.5}
                          >
                            <Center h="100%" p="20px">
                              {/* <Text color="white">Image Unavailable</Text> */}
                              <Image src={Logo} />
                            </Center>
                          </Box>
                        )}

                        {store.userData &&
                          store.userData.user.user_type === 'admin' && (
                            <IconButton
                              pos="absolute"
                              top="4px"
                              right="4px"
                              icon={<FaEdit />}
                              colorScheme="blue"
                              zIndex="99"
                              onClick={e => {
                                e.stopPropagation();
                                setlabel(d.title);
                                seturl(d.url);
                                setorder(d.priority);
                                setcat(d.category);
                                setuserid(d.author && d.author.id);
                                setfeatured(d.feature);
                                setimage(d.thumbnail);
                                setSelected(d);
                                onOpen();
                              }}
                            />
                          )}

                        <Box
                          pos="relative"
                          h="40%"
                          // borderLeft="5px solid #FBC02C"
                          p="6px"
                          pt="10px"
                          opacity={d.feature ? 1 : 0.5}
                        >
                          <Text
                            fontSize="18px"
                            lineHeight="18px"
                            fontWeight="bold"
                          >
                            {d.title.split('%')[0]}
                          </Text>
                          <Text fontSize="14px" lineHeight="28px">
                            {d.title.split('%')[1]}
                          </Text>
                          <Box
                            pos="absolute"
                            right="8px"
                            bottom="6px"
                            display="none"
                          >
                            <Flex alignItems="center">
                              <Box textAlign="right" mr="6px">
                                <Text fontSize="14px" fontWeight="bold">
                                  {d.author && d.author.full_name
                                    ? d.author.full_name
                                    : ''}
                                </Text>
                                <Text fontSize="12px">
                                  {d.author && d.author.company
                                    ? d.author.company
                                    : ''}
                                </Text>
                              </Box>

                              {d.author && d.author.profile_picture ? (
                                <Image
                                  mr="10px"
                                  backgroundColor="grey"
                                  borderRadius="full"
                                  boxSize="30px"
                                  src={d.author.profile_picture}
                                  alt={store.userData.user.full_name}
                                />
                              ) : (
                                <Circle size="30px" bg="grey" color="black">
                                  <Icon as={FaUser} boxSize="18px" circle />
                                </Circle>
                              )}
                            </Flex>
                          </Box>
                        </Box>
                        {store.userData &&
                          store.userData.user.user_type === 'admin' && (
                            <Text>{`Order: ${d.priority ?? 'Not Set'}`}</Text>
                          )}
                      </Box>
                    </WrapItem>
                  );
                })}
              </Wrap>
            </Box>
          );
        })}
    </Box>
  );
};

const VideoPlayer = ({ isOpen, onClose, data }) => {
  const [ready, setready] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setready(false);
    }
    return () => {};
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data && data.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box textAlign="center" display={!ready ? 'block' : 'none'}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
          <Box display={ready ? 'block' : 'none'}>
            {data && (
              <ReactPlayer
                onReady={() => setready(true)}
                playing={ready}
                controls={true}
                // width="100%"
                // height="100%"
                width="auto"
                height="480px"
                url={data.url}
              />
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeaturedPitchesX;
