/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  HStack,
  Center,
  useDisclosure,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Image,
  Wrap,
  WrapItem,
  Switch as CSwitch,
  Icon,
} from '@chakra-ui/react';

import { FaEdit } from 'react-icons/fa';
import { GiSoundWaves } from 'react-icons/gi';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { observer } from 'mobx-react';
import MainStore from '../stores/MainStore';

import Logo from '../assets/svhq_logo.png';

const FeaturedMembers = ({ title, subtitle, path, toGet, type }) => {
  const store = useContext(MainStore);
  let location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();
  const [label, setlabel] = useState('');
  const [url, seturl] = useState('');
  const [order, setorder] = useState(null);
  const [userid, setuserid] = useState(null);
  const [cat, setcat] = useState(null);
  const [featured, setfeatured] = useState(false);
  const [image, setimage] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setloading] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [members, setMembers] = useState([]);

  const [audio, setAudio] = useState();
  const [audioPlaying, setAudioPlaying] = useState(false);
  useEffect(() => {
    // audio.addEventListener('ended', () => setAudioPlaying(false));
  }, []);

  const toggleAudio = () => {
    if (!audioPlaying) {
      // audio
      let au = new Audio(
        'https://svhq-staging-bucket.s3.us-east-2.amazonaws.com/files/ChrisGPT+Mentors.mpeg'
      );
      au.play();
      setAudio(au);
      setAudioPlaying(true);
    } else {
      audio.pause();
      setAudioPlaying(false);
    }
  };

  const onFilesChange = files => {
    store._globalToast({
      title: 'File loaded successfully',
      description: files[0].name,
      status: 'success',
    });

    setimage(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onFilesError = (error, file) => {
    if (store.debugMode)
      console.log('error code ' + error.code + ': ' + error.message);
  };

  const clearFields = () => {
    setlabel('');
    seturl('');
    setorder('');
    setcat('');
    setuserid('');
    setfeatured(false);
    setimage('');
    setSelected(null);
    setimage('');
    setFile(null);
  };

  const updateData = () => {
    store[toGet]().then(res => {
      if (store.debugMode) console.log('GET!', res);
      setData(
        res.data.filter(d => d.feature).sort((a, b) => a.priority - b.priority)
      );
    });
    store._getMembersSearch({}).then(res => {
      setMembers(
        res.data
          .filter(d => d.user_type === type)
          .sort((a, b) => a.first_name.localeCompare(b.first_name))
      );
      // setData([...data, ...res.data]);
    });
  };

  useEffect(() => {
    if (location.pathname === path) {
      if (store.debugMode) console.log('FEATURED!', title, location.pathname);
      updateData();
    }
    return () => {};
  }, [location]);

  return (
    <Box px="36px" py="12px">
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected ? 'Edit' : 'Add New'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="10px">
              <Input
                value={order}
                onChange={e => setorder(e.target.value)}
                type="number"
                placeholder="Order"
              />
              <HStack>
                <CSwitch
                  size="md"
                  isChecked={featured}
                  onChange={e => setfeatured(e.target.checked)}
                />
                <Text>Featured</Text>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={loading}
              loadingText="Updating..."
              colorScheme="blue"
              onClick={() => {
                setloading(true);
                store
                  ._updateFeaturedMember({
                    feature: featured,
                    id: selected.id,
                    priority: order,
                  })
                  .then(res => {
                    setloading(false);
                    if (res) {
                      onClose();
                      updateData();
                    }
                  });
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'initial', lg: 'center' }}
        justifyContent="flex-start"
      >
        <Text fontSize="24px" fontWeight="bold">
          {title}
        </Text>
        <Text ml={{ base: '0px', lg: '10px' }} fontSize="16px" color="grey">
          {subtitle}
        </Text>
      </Flex>
      {/* {data.length <= 0 && <Text>No Featured Available</Text>} */}
      <Wrap mt="20px" spacing="40px">
        {data.map((d, i) => {
          return (
            <>
              {d.first_name + ' ' + d.last_name === 'Chris Peralta' ? (
                <WrapItem key={`d${i}`} display={'flex'}>
                  <Box
                    // onClick={() => history.push(`/user/profile/${d.id}`)}
                    onClick={toggleAudio}
                    w="240px"
                    h="240px"
                    cursor="pointer"
                    pos="relative"
                  >
                    {audioPlaying && (
                      <Box pos="absolute" top="100px" right="10px">
                        <Icon
                          as={GiSoundWaves}
                          w="40px"
                          h="40px"
                          color="white"
                        />
                      </Box>
                    )}

                    {d.profile_picture ? (
                      <Box
                        h="60%"
                        backgroundColor="black"
                        backgroundImage={d.profile_picture}
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center center"
                        boxShadow={
                          audioPlaying ? 'inset 0 0 100px #000000' : 'none'
                        }
                      >
                        {/* <Image
                        m="0 auto"
                        h="100%"
                        src={d.profile_picture}
                        objectFit="cover"
                      /> */}
                      </Box>
                    ) : (
                      <Box h="60%" backgroundColor="black">
                        <Center h="100%" p="20px">
                          {/* <Text color="white">Image Unavailable</Text> */}
                          <Image src={Logo} />
                        </Center>
                      </Box>
                    )}
                    <Box pos="relative" h="40%" p="6px">
                      <Text lineHeight="18px" fontWeight="bold">
                        {`${d.first_name} ${d.last_name}`}
                      </Text>
                      <Text lineHeight="18px" color="gray">
                        <Text>{`ChrisGPT`}</Text>
                        {/* {d.super_powers ? d.super_powers.join(', ') : 'N/A'} */}
                      </Text>
                    </Box>
                  </Box>
                </WrapItem>
              ) : (
                <WrapItem key={`d${i}`}>
                  <Box
                    onClick={() => history.push(`/user/profile/${d.id}`)}
                    w="240px"
                    h="240px"
                    cursor="pointer"
                    // boxShadow={d.feature ? 'xl' : 'none'}
                    // border={d.feature ? '4px solid gold' : 'none'}
                    pos="relative"
                  >
                    {d.profile_picture ? (
                      <Box
                        h="60%"
                        backgroundColor="black"
                        opacity={d.feature ? 1 : 0.5}
                      >
                        <Image
                          m="0 auto"
                          h="100%"
                          src={d.profile_picture}
                          objectFit="cover"
                        />
                      </Box>
                    ) : (
                      <Box
                        h="60%"
                        backgroundColor="black"
                        opacity={d.feature ? 1 : 0.5}
                      >
                        <Center h="100%" p="20px">
                          {/* <Text color="white">Image Unavailable</Text> */}
                          <Image src={Logo} />
                        </Center>
                      </Box>
                    )}

                    {store.userData &&
                      store.userData.user.user_type === 'admin' && (
                        <IconButton
                          pos="absolute"
                          top="4px"
                          right="4px"
                          icon={<FaEdit />}
                          colorScheme="blue"
                          zIndex="99"
                          onClick={e => {
                            e.stopPropagation();
                            onOpen();
                            setorder(data[i].priority);
                            setfeatured(data[i].feature);
                            setSelected(data[i]);
                          }}
                        />
                      )}

                    <Box pos="relative" h="40%" p="6px">
                      <Text lineHeight="18px" fontWeight="bold">
                        {/* {d.full_name} */}
                      </Text>
                      <Text lineHeight="18px" color="gray">
                        {d.company}
                        {/* {d.super_powers ? d.super_powers.join(', ') : 'N/A!'} */}
                      </Text>
                    </Box>
                    {store.userData &&
                      store.userData.user.user_type === 'admin' && (
                        <Text mt="6px !important">{`Order: ${
                          d.priority ?? 'Not Set'
                        }`}</Text>
                      )}
                  </Box>
                </WrapItem>
              )}
            </>
          );
        })}
        {members.map((d, i) => {
          return (
            <WrapItem key={`d${i}`} display={d.feature ? 'none' : 'flex'}>
              <Box
                onClick={() => history.push(`/user/profile/${d.id}`)}
                w="240px"
                h="240px"
                cursor="pointer"
                pos="relative"
              >
                {d.profile_picture ? (
                  <Box
                    h="60%"
                    backgroundColor="black"
                    backgroundImage={d.profile_picture}
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"
                  >
                    {/* <Image
                        m="0 auto"
                        h="100%"
                        src={d.profile_picture}
                        objectFit="cover"
                      /> */}
                  </Box>
                ) : (
                  <Box h="60%" backgroundColor="black">
                    <Center h="100%" p="20px">
                      {/* <Text color="white">Image Unavailable</Text> */}
                      <Image src={Logo} />
                    </Center>
                  </Box>
                )}

                {store.userData &&
                  store.userData.user.user_type === 'admin' && (
                    <IconButton
                      pos="absolute"
                      top="4px"
                      right="4px"
                      icon={<FaEdit />}
                      colorScheme="blue"
                      zIndex="99"
                      onClick={e => {
                        e.stopPropagation();
                        onOpen();
                        setorder(members[i].priority);
                        setfeatured(members[i].feature);
                        setSelected(members[i]);
                      }}
                    />
                  )}

                <Box pos="relative" h="40%" p="6px">
                  <Text lineHeight="18px" fontWeight="bold">
                    {`${d.first_name} ${d.last_name}`}
                  </Text>
                  <Text lineHeight="18px" color="gray">
                    {d.super_powers ? d.super_powers.join(', ') : 'N/A'}
                  </Text>
                </Box>
              </Box>
            </WrapItem>
          );
        })}
      </Wrap>
    </Box>
  );
};

export default FeaturedMembers;
